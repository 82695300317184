<template>
    <div class="mt-4">
      <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
        <div class="flex justify-center items-center">
          <h1 class="heading-2 text-center">Twilio Configuration</h1>
        </div>
      </div>
      
      <div class="flex justify-center " >
          <div class="sm:w-full w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
              <div class=" text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
                    <div class="flex items-center justify-between">
                      <div class=" text-text2" :class="mobileView ? 'heading-5' : 'heading-3'">● Twilio Configuration:</div>
                      <div>
                        <Button v-if="twilioCOnfList.length === 0" @buttonAction="selectNumber(0)" class="ml-2" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add Number'"/>
                        <div v-if="twilioCOnfList.length > 0" class=" text-white heading-4 cursor-pointer bg-primary px-2.5 py-1 rounded-xl" @click="selectNumber(twilioCOnfList[0].twilioConfigurationId)">
                          <i class="fa-solid fa-pen-to-square"></i>
                        </div>
                      </div>
                    </div>
                    <div class="pb-0 mt-8" v-if="twilioCOnfList.length > 0">
                      <div class="relative" @click="openDetail= !openDetail">
                        <div class="card rounded-xl bg-white p-3 cursor-pointer relative border-t border-gray-100">
                          <div class="text-text1 flex justify-between">
                            <p class="pl-4">{{twilioCOnfList[0].configLabel}}</p>
                            <p>{{twilioCOnfList[0].phoneNumber | formatPhoneNumber}}</p>
                          </div>
                          <div class="pr-2 absolute left-1 top-3">
                            <span v-if="twilioCOnfList[0].isActive" class="text-success heading-7 pl-1 rounded-full"><i class="fa-solid fa-circle"></i></span>
                            <p v-if="!twilioCOnfList[0].isActive" class="text-error heading-7 pl-1 rounded-full"><i class="fa-solid fa-circle"></i></p>
                          </div>
                          <div v-if="openDetail" class="card rounded-xl bg-white p-3 cursor-pointer relative">
                            <div class="flex items-center">
                              <p class=" text-gray4 cardWidth">Acc. SID</p>
                              <p class="pr-2">:</p>
                              <p class="text-text2">{{twilioCOnfList[0].accountSid}}</p>
                            </div>
                            <div class="flex items-center">
                              <p class=" text-gray4 cardWidth">Acc. AuthToken</p>
                              <p class="pr-2">:</p>
                              <p class="text-text2">{{twilioCOnfList[0].appSid}}</p>
                            </div>
                            <div class="flex items-center">
                              <p class=" text-gray4 cardWidth">Msg. Service SID</p>
                              <p class="pr-2">:</p>
                              <p class="text-text2">{{twilioCOnfList[0].messagingServiceSid}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <NoRecords :alertMessage="`No record found.`" />
                    </div>
              </div>
          </div>
          <div v-if="twilioConfPopup" class="popup_overlay relative px-4">
            <div style="width: 800px;max-height: 87vh;overflow: auto" class="custom_dialog rounded-xl h-min">
              <div  class=" bg-primary flex justify-between p-3 sticky top-0 z-10">
                <p class="heading-4 text-white">{{addNumberObject.twilioConfigurationId > 0 ? 'Edit Twilio Configuration' : 'Add Twilio Configuration'}}</p>
              </div>
              <div class="flex p-2 m-2 text-error bg-red-100 rounded-lg  items-center" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <div>
                  <p class=" font-medium heading-6">Please be cautious to {{addNumberObject.twilioConfigurationId > 0 ? 'change' : 'add'}} the values, in case of incorrect values the functionality may break.</p>
                </div>
              </div>
              <div class="p-4 grid lg:grid-cols-2 gap-2">
                <div>
                  <TextField
                    :inputId="'TwilName'"
                    :showcharLimit="false"
                    :inputext="addNumberObject.configLabel"
                    :placholderText="`Config Label`"
                    :lableText="'Config Label'"
                    :autoFocus="false"
                    :fieldError="nameErr !== ''"
                    @keyPressAction="nameErr = ''"
                    @inputChangeAction="(data) => addNumberObject.configLabel = data"  />
                    <p class="text-error heading-7">{{nameErr}}</p>
                </div>
                <div>
                  <NumberInput
                    :inputId="'TwilNumber'"
                    :showcharLimit="false"
                    :textMaxlength="12"
                    :inputext="addNumberObject.phoneNumber"
                    :placholderText="`Phone Number`"
                    :lableText="'Phone Number'"
                    :autoFocus="false"
                    @keyPressAction="numErr = ''"
                    @blurAction="checkContactNumber()"
                    :fieldError="numErr !== ''"
                    @inputChangeAction="(data) => addNumberObject.phoneNumber = data"  />
                    <p class="text-error heading-7">{{numErr}}</p>
                </div>
                <div>
                  <TextField
                    :inputId="'TwilSid'"
                    :showcharLimit="false"
                    :inputext="addNumberObject.accountSid"
                    :placholderText="`Account SID`"
                    :lableText="'Account SID'"
                    :autoFocus="false"
                    @keyPressAction="accSidErr = ''"
                    :fieldError="accSidErr !== ''"
                    @inputChangeAction="(data) => addNumberObject.accountSid = data"  />
                    <p class="text-error heading-7">{{accSidErr}}</p>
                </div>
                <div>
                  <TextField
                    :inputId="'TwilAuth'"
                    :showcharLimit="false"
                    :inputext="addNumberObject.appSid"
                    :placholderText="`Account AuthToken`"
                    :lableText="'Account AuthToken'"
                    :autoFocus="false"
                    @keyPressAction="appSIDErr = ''"
                    :fieldError="appSIDErr !== ''"
                    @inputChangeAction="(data) => addNumberObject.appSid = data"  />
                    <p class="text-error heading-7">{{appSIDErr}}</p>
                </div>
                <!-- <div>
                  <TextField
                    :inputId="'TwilAKey'"
                    :showcharLimit="false"
                    :inputext="addNumberObject.apiKey"
                    :placholderText="`Api Key`"
                    :lableText="'Api Key'"
                    :autoFocus="false"
                    @keyPressAction="apiKeyErr = ''"
                    :fieldError="apiKeyErr !== ''"
                    @inputChangeAction="(data) => addNumberObject.apiKey = data"  />
                    <p class="text-error heading-7">{{apiKeyErr}}</p>
                </div>
                <div>
                  <TextField
                    :inputId="'TwilASec'"
                    :showcharLimit="false"
                    :inputext="addNumberObject.apiSecret"
                    :placholderText="`Api Secret`"
                    :lableText="'Api Secret'"
                    :autoFocus="false"
                    @keyPressAction="apiSecretErr = ''"
                    :fieldError="apiSecretErr !== ''"
                    @inputChangeAction="(data) => addNumberObject.apiSecret = data"  />
                    <p class="text-error heading-7">{{apiSecretErr}}</p>
                </div> -->
                <div>
                  <TextField
                    :inputId="'mesSerSid'"
                    :showcharLimit="false"
                    :inputext="addNumberObject.messagingServiceSid"
                    :placholderText="`Messaging Service SID`"
                    :lableText="'Messaging Service SID'"
                    :autoFocus="false"
                    @keyPressAction="msgSerErr = ''"
                    :fieldError="msgSerErr !== ''"
                    @inputChangeAction="(data) => addNumberObject.messagingServiceSid = data"  />
                    <p class="text-error heading-7">{{msgSerErr}}</p>
                </div>
              </div>
                <div class="flex items-center px-4">
                  <label class="cu_switch cursor-pointer mr-3" :class="!addNumberObject.isActive ? 'ml-2' : ''">
                    <input type="checkbox" v-model="addNumberObject.isActive">
                    <span class="cu_slider cu_round"></span>
                  </label>
                  <p class="text-gray3 pl-1">{{addNumberObject.isActive ? 'Number is Active' : 'Number is Inactive'}}</p>
                </div>
              <div class="flex gap-2 justify-end p-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="clickOnBtn"/>
                <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save'" @buttonAction="saveAPI"/>
              </div>
            </div>
          </div>
      </div>
    </div>
  </template>
  <script>
  import { checkSpeialChar } from "@/utils/validation.js";
  import NoRecords from '@/View/components/noRecords.vue'
  import NumberInput from '@/View/components/number-text-field.vue'
  import TextField from '@/View/components/textfield.vue'
  import Button from '@/View/components/globalButton.vue'
  import MyJobApp from "@/api/MyJobApp.js";
  export default {
    name: "customer",
    components: {
        NoRecords,
        TextField,
        NumberInput,
        Button
    },
    data() {
      return {
        mobileView: false,
        nameErr: '',
        openDetail: false,
        numErr: '',
        accSidErr: '',
        appSIDErr: '',
        apiKeyErr: '',
        apiSecretErr: '',
        msgSerErr: '',
        addNumberObject: {
            twilioConfigurationId: 0,
            configLabel: '',
            phoneNumber: '',
            accountSid: '',
            appSid: '',
            apiKey: '',
            apiSecret: '',
            messagingServiceSid: '',
            twimalAppId: '',
            isActive: true,
        },
          twilioCOnfList: [],
          twilioConfPopup: false,
      };
    },
    created() {
        window.addEventListener("resize", this.resizeWindowHandler);
    },
    mounted() {
      this.resizeWindowHandler();
      this.getTwilioListing()
    },
    methods: {
      resizeWindowHandler() {
          if (window.innerWidth < 684) {
              this.mobileView = true;
          } else {
              this.mobileView = false;
          }
      },
      selectNumber (data) {
        if (data > 0) {
          this.addNumberObject.twilioConfigurationId = data
          this.addNumberObject.configLabel = this.twilioCOnfList[0].configLabel
          this.addNumberObject.phoneNumber = this.twilioCOnfList[0].phoneNumber
          this.addNumberObject.accountSid = this.twilioCOnfList[0].accountSid
          this.addNumberObject.appSid = this.twilioCOnfList[0].appSid
          this.addNumberObject.apiKey = this.twilioCOnfList[0].apiKey
          this.addNumberObject.apiSecret = this.twilioCOnfList[0].apiSecret
          this.addNumberObject.messagingServiceSid = this.twilioCOnfList[0].messagingServiceSid
          this.addNumberObject.twimalAppId = this.twilioCOnfList[0].twimalAppId
          this.addNumberObject.isActive = this.twilioCOnfList[0].isActive
        }
          this.twilioConfPopup = true
      },
      checkContactNumber () {
        let value = checkSpeialChar(this.addNumberObject.phoneNumber)
        console.log('value', value)
        if (value) {
          this.numErr = 'Please enter valid contact number'
        } else {
          this.numErr = ''
        }
      },
      saveAPI () {
          let isvalid = true
          if (this.addNumberObject.configLabel === '') {
              this.nameErr = 'Name is required'
              isvalid = false
          }
          if (this.addNumberObject.phoneNumber === '') {
              this.numErr = 'Phone Number is required'
              isvalid = false
          }
          if (this.numErr !== '') {
              isvalid = false
          }
          if (this.addNumberObject.accountSid === '') {
              this.accSidErr = 'Account SID is required'
              isvalid = false
          }
          if (this.addNumberObject.appSid === '') {
              this.appSIDErr = 'App SID is required'
              isvalid = false
          }
          // if (this.addNumberObject.apiKey === '') {
          //     this.apiKeyErr = 'Api Key is required'
          //     isvalid = false
          // }
          // if (this.addNumberObject.apiSecret === '') {
          //     this.apiSecretErr = 'Api Secret is required'
          //     isvalid = false
          // }
          if (this.addNumberObject.messagingServiceSid === '') {
              this.msgSerErr = 'Messaging Service SID is required'
              isvalid = false
          }
          if (isvalid) {
              this.$store.dispatch('SetLoader', {status: true, message: 'Please wait...'})
              MyJobApp.TwilioConfigSave(
                this.addNumberObject,
                response => {
                  this.twilioConfPopup = false
                  this.getTwilioListing()
                  this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
                  this.$store.dispatch('SetLoader', {status: false, message: ''})
                },
                (error) => {
                  this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
                  this.$store.dispatch('SetLoader', {status: false, message: ''})
                }
              )
          }
      },
      clickOnBtn () {
        this.addNumberObject.twilioConfigurationId = 0
        this.addNumberObject.configLabel = ''
        this.addNumberObject.phoneNumber = ''
        this.addNumberObject.accountSid = ''
        this.addNumberObject.appSid = ''
        this.addNumberObject.apiKey = ''
        this.addNumberObject.apiSecret = ''
        this.addNumberObject.messagingServiceSid = ''
        this.nameErr = ''
        this.numErr = ''
        this.accSidErr = ''
        this.appSIDErr = ''
        this.apiKeyErr = ''
        this.apiSecretErr = ''
        this.msgSerErr = ''
        this.twilioConfPopup = false
      },
      getTwilioListing() {
        this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
        MyJobApp.GetTwilioList(
          0,
          10,
          '',
          '',
          '',
          '',
          (response) => {
              this.twilioCOnfList = response.Data.tableRow !== null ? response.Data.tableRow : []
              this.$store.dispatch("SetLoader", { status: false, message: "" });
          },
          (err) => {
              this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
              this.$store.dispatch("SetLoader", { status: false, message: "" });
          }
        );
      },
      },
      beforeDestroy() {
      },
    };
  </script>
  <style scoped>
  .table_col {
    overflow-wrap: anywhere;
    white-space: pre-wrap;
    padding-right: 8px;
  }
  .name_col {
      min-width: 200px;
      max-block-size: 200px;
  }
  .des_col {
      min-width: 250px;
      max-block-size: 250px;
  }
  .pric_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .qnty_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .amt_col {
      min-width: 100px;
      max-block-size: 100px;
  }
  .cardWidth {
    width: 145px;
  }
  </style>